import { get } from './http.js'

/**
 * 获取专辑列表
 */
export const getTopicList = () => get(`/article/v1.0/topic`)

/**
 * 获取专栏信息
 */
export const getTopicInfo = (id) => get(`/article/v1.0/topic/${id}`)

/**
 *  专栏下的文章列表
 */
export const getTopicArticleList = (id, sort, pageNum, pageSize) => {
    let params = {
        sort,
        pageNum,
        pageSize
    }

    return get(`/article/v1.0/topic/${id}/articles`, params)
}
