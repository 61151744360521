<template>
  <div>
    <div class="columnist">
      <div class="columnist__bk">
        <img class="columnist__bk-img" :src="topic.topicCover" />
        <div class="columnist__bk-mask"></div>
      </div>
      <img class="columnist__cover" :src="topic.topicCover" />
      <div style="z-index: 1">
        <div class="columnist__title">{{ topic.topicName }}</div>
        <div>已更新{{ topic.articleNum }}期 | {{ topic.favNum }}人收藏</div>
      </div>
      <div class="sc-box" v-if="!topic.fav" @click="onFavoritesClick">
        <img class="sc-img" src="@/assets/imgs/columnist/add.png">
        收藏
      </div>
      <div v-else class="collect-icon" @click="onFavoritesClick">
        已收藏
      </div>
    </div>
    <div class="columnist-box__header">
      <span>课程目录</span>
      <div class="columnist-box__header-sort" @click="onSort">
        <img
          v-if="ascSort"
          class="columnist-box__header-sort-icon"
          src="@/assets/imgs/columnist/asc.png"
        />
        <img
          v-else
          class="columnist-box__header-sort-icon"
          src="@/assets/imgs/columnist/desc.png"
        />
        <span style="line-height: 18px">{{ ascSort ? "正序" : "倒序" }}</span>
      </div>
    </div>
    <van-list
      v-model="loading"
      :error.sync="listError"
      error-text="请求失败，点击重新加载"
      finished-text="到底了哦~"
      :finished="finished"
      @load="getList"
    >
      <topic-list-item
        v-for="(item, idx) in dataList"
        :key="idx"
        :info="item"
        @click.native="onArticle(item.id)"
      >
      </topic-list-item>
    </van-list>
  </div>
</template>

<script>
import TopicListItem from "./TopicListItem.vue";
import { getTopicArticleList, getTopicInfo } from "@/api/topic";
import { toggleFavorites } from "@/api/mine";
import { List } from "vant";
import to from "@/utils/to";

export default {
  components: {
    TopicListItem,
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      listError: false,
      finished: false,

      dataList: [],
      pageNum: 1, // 分页

      topic: {},
      ascSort: false,
      topicId: "",
    };
  },
  mounted() {
    this.topicId = this.$route.query.id;
    this.getTopicInfo();
  },
  methods: {
    onFavoritesClick() {
      this.topic['fav'] = !this.topic.fav
      toggleFavorites(this.topicId, 'topic')
    },
    onSort() {
      this.ascSort = !this.ascSort;
      this.pageNum = 1;
      this.dataList = [];
      this.getList();
    },
    async getTopicInfo() {
      this.$showLoading("加载中...");
      let [err, res] = await to(getTopicInfo(this.topicId));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }

      this.topic = res;
    },
    async getList() {
      const type = this.ascSort ? "asc" : "desc";
      let [err, res] = await to(
        getTopicArticleList(this.topicId, type, this.pageNum, 10)
      );

      if (err) {
        this.listError = true;
        return;
      }

      if (res.rows.length === 0) {
        this.finished = true;
        return;
      }

      this.dataList = this.dataList.concat(res.rows);

      this.loading = false;
      this.pageNum = res.pageNum + 1;
    },
    onArticle(id) {
      this.$router.push({
        name: "Article", query: { id }
      });
    },
  },
};
</script>

<style scoped>
.columnist {
  height: 150px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #cccccc;
  position: relative;
}

.columnist__bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.columnist__bk-img {
  filter: blur(20px);
  transform: scale(1.4);
}

.columnist__bk-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.columnist__cover {
  width: 110px;
  height: 75px;
  border-radius: 6px;
  margin: 0 18px 0 16px;
  z-index: 1;
}

.columnist__title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 6px;
  z-index: 1;
  text-align: left;
}

.sc-box {
  z-index: 1;
  padding: 2px 10px;
  background: #ffc83e;
  border-radius: 12px;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.sc-img {
  width: 12px;
  height: 12px;
}

.collect-icon {
  z-index: 1;
  padding: 2px 10px;
  border: 1px solid #B3B3B3;
  border-radius: 10px;
  font-size: 12px;
  line-height: 17px;
  color: #B3B3B3;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.columnist-box__header {
  padding: 16px 16px 0 16px;
  color: #333;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.columnist-box__header-sort {
  font-weight: normal;
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
}

.columnist-box__header-sort-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
</style>